import {
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import AddHome from "@mui/icons-material/AddHome";
import CameraAlt from "@mui/icons-material/CameraAlt";
import { LoadingButton } from "@mui/lab";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resizeFile } from "../../functions";
import { auth } from "../../firebase";

const AddHouse = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const categories = [];
  const dispatch = useDispatch();
  const loading = false;
  const types = [
    "Maison villa",
    "Maison commerciale",
    "Bureau",
    "Appartement",
    "Terrain",
    "Dépôt",
    "Immeuble",
    "Maisom meublée",
    "Appartement meublé",
    "Maison duplex",
    "Maison triplex",
  ];

  const cities = [
    "Kinshasa",
    "Lubumbashi",
    "Goma",
    "Bukavu",
    "Kolwezi",
    "Brazzaville",
    "Pointe-Noire",
    "Douala",
    "Yaoundé",
    "Limbe",
    "Kribi",
    "Bafoussam",
  ];

  const handleChange = (e) =>
    setProduct({ ...product, [e.target.name]: e.target.value });

  const loadFile = async (event) => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById("output");
      output.src = reader.result;
    };
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
      const image = await resizeFile(event.target.files[0]);
      setProduct({ ...product, image });
    }
  };

  const redirect = () => navigate("/menu");

  const handleSubmit = (e) => {
    e.preventDefault();
    // dispatch(createProduct(product, redirect));
  };

  return (
    <main>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader title="Ajouter une maison" icon={AddHome} />
        </Grid>
      </Grid>
      <Paper elevation={0} sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                name="category"
                label="Motif"
                required
                fullWidth
                select
                onChange={handleChange}
              >
                <MenuItem value={"rental"}>{"Location"} </MenuItem>
                <MenuItem value={"sale"}>{"Vente"} </MenuItem>
                <MenuItem value={"concept"}>{"Plan de maison"} </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="city"
                label="Ville"
                required
                fullWidth
                select
                onChange={handleChange}
              >
                {cities &&
                  cities.map((city) => (
                    <MenuItem value={city}>{city} </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                name="type"
                label="Type de maison"
                required
                fullWidth
                select
                onChange={handleChange}
              >
                {types &&
                  types.map((type) => (
                    <MenuItem value={type}>{type} </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                name="price"
                label="Prix ($)"
                required
                type="text"
                fullWidth
                onChange={(e) =>
                  setProduct({ ...product, price: parseInt(e.target.value) })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                fullWidth
                multiline
                rows={4}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="import"
                type="file"
                onChange={loadFile}
                multiple
              />
              <label htmlFor="import">
                <Button
                  startIcon={<CameraAlt />}
                  variant="outlined"
                  component="span"
                >
                  Importer des images
                </Button>
              </label>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center" }}>
                <img id="output" width={200} />
              </Typography>
            </Grid>
            <Grid item xs={4} md={8}></Grid>
            <Grid item md={4}>
              <LoadingButton
                loading={loading}
                variant="contained"
                fullWidth
                type="submit"
                sx={{ color: "white" }}
                disableElevation
              >
                Valider
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </main>
  );
};

export default AddHouse;
