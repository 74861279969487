import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MarkunreadMailboxIcon from "@mui/icons-material/MarkunreadMailbox";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AddHomeIcon from "@mui/icons-material/AddHome";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import ArchitectureIcon from "@mui/icons-material/Architecture";

const iconProps = {
  color: "inherit",
};

export const drawerItems = [
  {
    text: "Tableau de bord",
    icon: <DashboardIcon {...iconProps} />,
    path: "/tableau-de-bord",
  },
  {
    text: "Nouvelles Offres",
    icon: <MarkunreadMailboxIcon {...iconProps} />,
    path: "/nouvelles-offres",
  },
  {
    text: "Maisons à Louer",
    icon: <NightShelterIcon {...iconProps} />,
    path: "/maisons-a-louer",
  },
  {
    text: "Maisons à Vendre",
    icon: <OtherHousesIcon {...iconProps} />,
    path: "/maisons-a-vendre",
  },
  {
    text: "Plans de maison",
    icon: <ArchitectureIcon {...iconProps} />,
    path: "/plans-de-maison",
  },
  {
    text: "Décoration",
    icon: <FormatPaintIcon {...iconProps} />,
    path: "/decoration",
  },
];

export const secondaryListItems = [
  {
    text: "Ajouter une maison",
    icon: <AddHomeIcon {...iconProps} />,
    path: "/ajouter-une-maison",
  },
  {
    text: "Ajouter décoration",
    icon: <AddBusinessIcon {...iconProps} />,
    path: "/ajouter-un-business",
  },
];
