import { LoadingButton } from "@mui/lab";
import { Button, Grid, Link, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/actions/authActions";
import Logo from "../icons/Logo";
import { useNavigate } from "react-router-dom";

const mapState = ({ auth }) => ({
  loading: auth.loading,
});

function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [credentials, setCredentials] = useState({});
  const { loading } = useSelector(mapState);

  const handleChange = (e) =>
    setCredentials({ ...credentials, [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(credentials));
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Logo width={150} />
        </Grid>
        <Grid sx={{ marginTop: -2 }} item xs={12}>
          <Typography
            color="textPrimary"
            sx={{ fontWeight: "600" }}
            variant="h4"
          >
            Se connecter
          </Typography>
          <Typography>
            Suivez en temps réel l'activité de votre entreprise.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="email"
            label="Identifiant"
            required
            fullWidth
            variant="outlined"
            type="email"
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="password"
            label="Mot de passe"
            fullWidth
            required
            variant="outlined"
            type="password"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          {/* <Typography sx={{ fontSize: 14 }} >Vous n'avez pas de compte?
                        <Button
                            onClick={() => navigate('/register')}
                            color="primary"
                            component="a"
                            underline="none"
                        >
                            {' '}  Inscrivez-vous
                        </Button>
                    </Typography> */}
        </Grid>
        <Grid item xs={12} md={4}>
          <LoadingButton
            type="submit"
            loading={loading}
            variant="contained"
            sx={{ color: "#fff" }}
            color="primary"
            fullWidth
          >
            Se connecter
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}

export default LoginForm;
