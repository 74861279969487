import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../../firebase";

export const login = (credentials) => {
  return (dispatch) => {
    dispatch({ type: "LOGIN_LOADING", payload: true });

    return signInWithEmailAndPassword(
      auth,
      credentials.email,
      credentials.password
    )
      .then((response) => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "LOGIN_ERROR", payload: err.message });
      });
  };
};

export const logOut = () => {
  return (dispatch) => {
    signOut()
      .then(() => {
        dispatch({ type: "LOGOUT_SUCCESS" });
      })
      .catch((err) => {
        dispatch({ type: "LOGOUT_ERROR", err });
      });
  };
};
