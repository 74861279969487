import Login from "./views/auth";
import Dashboard from "./views/dashboard";
import RentalHouses from "./views/marketplace/Rental";
import SaleHouses from "./views/marketplace/Sale";
import Offers from "./views/offers";
import AddHouse from "./views/marketplace/Add";
import Business from "./views/business";
import AddBusiness from "./views/business/Add";
import Settings from "./views/settings";
import Concept from "./views/marketplace/Concept";

const routes = [
  {
    name: "login",
    path: "/",
    component: <Login />,
  },
  {
    name: "Dashboard",
    path: "/tableau-de-bord",
    component: <Dashboard />,
  },
  {
    name: "Offers",
    path: "/nouvelles-offres",
    component: <Offers />,
  },
  {
    name: "Sale",
    path: "/maisons-a-vendre",
    component: <SaleHouses />,
  },
  {
    name: "Rental",
    path: "/maisons-a-louer",
    component: <RentalHouses />,
  },
  {
    name: "Rental",
    path: "/maisons-a-louer",
    component: <RentalHouses />,
  },
  {
    name: "AddHouse",
    path: "/ajouter-une-maison",
    component: <AddHouse />,
  },
  {
    name: "TepConcept",
    path: "/plans-de-maison",
    component: <Concept />,
  },
  {
    name: "Decoration",
    path: "/decoration",
    component: <Business />,
  },
  {
    name: "AddDeco",
    path: "/ajouter-un-decorateur",
    component: <AddBusiness />,
  },
  {
    name: "Settings",
    path: "/parametres",
    component: <Settings />,
  },
];

export default routes;
