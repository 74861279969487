import { Box, Button, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import { Navigate } from "react-router-dom";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import SearchBar from "../../components/includes/SearchBar";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import House from "../../components/product/House";

const Concept = () => {
  const [houses, setHouses] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [priceFilter, setPriceFilter] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const colRef = collection(db, "products");
    const q = query(
      colRef,
      where("type", "==", "concept"),
      orderBy("createdAt", "desc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let content = [];
      if (snapshot.docs) {
        snapshot.docs.forEach((doc) =>
          content.push({ id: doc.id, ...doc.data() })
        );
        setHouses(content);
        setData(content);
        setLoading(false);
      }
    });

    // Unsubscribe from events when no longer in use
    return () => unsubscribe();
  }, [houses]);

  const searchFilter = (text) => {
    const newData =
      houses &&
      houses.filter((item) => {
        const itemData = item.description
          ? item.description.toUpperCase()
          : "".toUpperCase();
        const textData = text.toUpperCase();
        return itemData.indexOf(textData) > -1;
      });
    setSearch(text);
    setData(newData);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DashboardPageHeader title="Plans de maison" icon={NightShelterIcon} />
      </Grid>
      <Grid item xs={12}>
        <SearchBar
          searchFilter={searchFilter}
          placeholder={"Rechercher un plan..."}
        />
      </Grid>
      {data && data.map((house) => <House data={house} />)}
    </Grid>
  );
};

export default Concept;
