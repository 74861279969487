import { Grid } from "@mui/material";
import React from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import { Navigate } from "react-router-dom";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { auth } from "../../firebase";

const AddBusiness = () => {
  const admin = auth.currentUser.uid;
  if (admin)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader
            title="Ajouter un service"
            icon={AddBusinessIcon}
          />
        </Grid>
      </Grid>
    );
  return <Navigate to="/" replace={true} />;
};

export default AddBusiness;
