import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAgRhN5NYMZis1cDE87VB4bAGrOdIUivEw",
  authDomain: "tep-immo.firebaseapp.com",
  projectId: "tep-immo",
  storageBucket: "tep-immo.appspot.com",
  messagingSenderId: "211354928088",
  appId: "1:211354928088:web:f9160a3b5944417be068ad",
  measurementId: "G-5194PG93YK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
