import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Navigate } from "react-router";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import OffersStats from "../../components/dashboard/OffersStats";
import UsersStats from "../../components/dashboard/UsersStats";
import RentalStats from "../../components/dashboard/RentalStats";
import SaleStats from "../../components/dashboard/SaleStats";
import { auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";

const Dashboard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DashboardPageHeader title="Tableau de bord" icon={DashboardIcon} />
      </Grid>

      <OffersStats />
      <RentalStats />
      <SaleStats />
      <UsersStats />
    </Grid>
  );
};

export default Dashboard;
