import React from "react";
import Footer from "./footer";
import { Box, Container, CssBaseline, Toolbar } from "@mui/material";
import Navbar from "./navbar";
import { auth } from "../firebase";

const Layout = ({ children }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <Navbar />

      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          {children}
          <Footer sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;
