import { Grid } from "@mui/material";
import React from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import { Navigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";

const Settings = () => {
  const user = "null";
  if (user)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader title="Paramètres" icon={SettingsIcon} />
        </Grid>
      </Grid>
    );
  return <Navigate to="/" replace={true} />;
};

export default Settings;
