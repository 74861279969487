import { Grid } from "@mui/material";
import React from "react";
import DashboardPageHeader from "../../components/includes/DashboardPageHeader";
import { Navigate } from "react-router-dom";
import FormatPaintIcon from "@mui/icons-material/FormatPaint";
import { auth } from "../../firebase";

const Business = () => {
  const admin = auth.currentUser.uid;

  if (admin)
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DashboardPageHeader title="Décoration" icon={FormatPaintIcon} />
        </Grid>
      </Grid>
    );
  return <Navigate to="/" replace={true} />;
};

export default Business;
