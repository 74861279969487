import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import { Navigate } from "react-router";
import LoginForm from "../../components/auth/Form";
import { auth } from "../../firebase";

function Login() {
  return (
    <Grid container spacing={3}>
      <Grid item md={3}></Grid>
      <Grid item xs={12} md={6}>
        <Paper sx={{ p: 5 }}>
          <LoginForm />
        </Paper>
      </Grid>
      <Grid item md={3}></Grid>
    </Grid>
  );
}

export default Login;
